<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="my-profile"
        :canDelete="false"
        ignoreID
        navigation="my-profile"
        title="My Profile">
        <template slot-scope="{ item, data }">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            :rules="requiredRules()"
                            required
                            v-model="item.userName"
                            label="User Name"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            :rules="requiredRules()"
                            v-model="item.email"
                            label="Email Address"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.firstName"
                            label="First Name"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.lastName"
                            label="Last Name"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.phoneNumber"
                            label="Phone Number"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col v-if="item.isGlobalAdmin" cols="12" sm="6">
                        <v-card>
                            <v-card-title>Global Admin</v-card-title>
                            <v-card-text>
                                You have access to absolutely everything.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <!-- <v-container v-if="item.isGlobalAdmin">
                <v-row>
                    <v-col cols="12">
                        **Global Admin** - means has access to absolutely everything.
                    </v-col>
                </v-row>
            </v-container> -->

            <v-expansion-panels v-model="panelV" multiple>
                <BT-Blade-Expansion-Items
                    addBladeName="role"
                    :canExportCSV="false"
                    :canRefresh="false"
                    :headers="[
                        { text: 'Role Name', value: 'roleName', title: 1 },
                        { text: 'Description', value: 'description', subtitle: 1 }]"
                    hideActions
                    :items="item.roles"
                    showList
                    title="Roles">
                    <template v-slot:actions>
                        <v-btn class="primary" small :to="{ name: 'users-board' }">Manage</v-btn>
                    </template>
                </BT-Blade-Expansion-Items>
                <BT-Blade-Expansion-Items
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    hideActions
                    :items="item.roles"
                    :onPullSuccessAsync="pullPermissions"
                    showList
                    title="Permissions">
                    <template v-slot:listItem="{ item }">
                        <v-list-item-action>
                            <v-row dense>
                                <v-icon title="View" small :class="item.canView || item.canEdit ? 'success--text' : ''" left>mdi-eye</v-icon>
                                <v-icon title="Edit" small :class="item.canEdit ? 'success--text' : ''" right>mdi-pencil</v-icon>
                            </v-row>
                        </v-list-item-action>
                        <v-list-item-content>
                            {{ item.text }}
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>
        </template> 
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'My-Profile',
    data: function() {
        return {
            panelV: [0,1]
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        pullPermissions(roles) {
            if (!this.isLengthyArray(roles)) {
                return [];
            }

            return roles
                .flatMap(x => {
                    return x.permissions.split(',');
                })
                .reduce((prev, curr) => {
                    var split = curr.split('.');
                    var p = {
                        text: this.capitalizeWords(split[0].replaceAll('-', ' ')),
                        value: split[0],
                        canEdit: false,
                        canView: false
                    }

                    var existing = prev.find(y => y.value == p.value);
                    if (existing == null) {
                        existing = p;
                        prev.push(existing);
                    }

                    if (split.length > 1) {
                        //can view or can edit?
                        if (split[1] == 'edit') {
                            existing.canEdit = true;
                        }
                        if (split[1] == 'view') {
                            existing.canView = true;
                        }
                    }
                    
                    return prev;
                }, [])
                .sort(firstBy(x => x.text, { ignoreCase: true }));
        }
    }
}
</script>