var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Item',{attrs:{"bladesData":_vm.bladesData,"bladeName":"my-profile","canDelete":false,"ignoreID":"","navigation":"my-profile","title":"My Profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var data = ref.data;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BT-Field-String',{attrs:{"rules":_vm.requiredRules(),"required":"","label":"User Name","isEditing":data.isEditing || data.isNew},model:{value:(item.userName),callback:function ($$v) {_vm.$set(item, "userName", $$v)},expression:"item.userName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BT-Field-String',{attrs:{"rules":_vm.requiredRules(),"label":"Email Address","isEditing":data.isEditing || data.isNew},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BT-Field-String',{attrs:{"label":"First Name","isEditing":data.isEditing || data.isNew},model:{value:(item.firstName),callback:function ($$v) {_vm.$set(item, "firstName", $$v)},expression:"item.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BT-Field-String',{attrs:{"label":"Last Name","isEditing":data.isEditing || data.isNew},model:{value:(item.lastName),callback:function ($$v) {_vm.$set(item, "lastName", $$v)},expression:"item.lastName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('BT-Field-String',{attrs:{"label":"Phone Number","isEditing":data.isEditing || data.isNew},model:{value:(item.phoneNumber),callback:function ($$v) {_vm.$set(item, "phoneNumber", $$v)},expression:"item.phoneNumber"}})],1),(item.isGlobalAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("Global Admin")]),_c('v-card-text',[_vm._v(" You have access to absolutely everything. ")])],1)],1):_vm._e()],1)],1),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panelV),callback:function ($$v) {_vm.panelV=$$v},expression:"panelV"}},[_c('BT-Blade-Expansion-Items',{attrs:{"addBladeName":"role","canExportCSV":false,"canRefresh":false,"headers":[
                    { text: 'Role Name', value: 'roleName', title: 1 },
                    { text: 'Description', value: 'description', subtitle: 1 }],"hideActions":"","items":item.roles,"showList":"","title":"Roles"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"primary",attrs:{"small":"","to":{ name: 'users-board' }}},[_vm._v("Manage")])]},proxy:true}],null,true)}),_c('BT-Blade-Expansion-Items',{attrs:{"canExportCSV":false,"canRefresh":false,"canSelect":false,"hideActions":"","items":item.roles,"onPullSuccessAsync":_vm.pullPermissions,"showList":"","title":"Permissions"},scopedSlots:_vm._u([{key:"listItem",fn:function(ref){
                    var item = ref.item;
return [_c('v-list-item-action',[_c('v-row',{attrs:{"dense":""}},[_c('v-icon',{class:item.canView || item.canEdit ? 'success--text' : '',attrs:{"title":"View","small":"","left":""}},[_vm._v("mdi-eye")]),_c('v-icon',{class:item.canEdit ? 'success--text' : '',attrs:{"title":"Edit","small":"","right":""}},[_vm._v("mdi-pencil")])],1)],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }